<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  left: unset !important;
  right: 15px !important;
  margin-top: -5px;
}
</style>
<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1  d-lg-flex">
      <!--<dark-Toggler class="d-none d-lg-block" />
      <span class="ml-50 locale-link"> | </span>-->
      <Bookmarks class=" d-lg-flex" />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--<Locale class="d-lg-block" />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link "
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
            </p>
          </div>
          <b-avatar
            size="40"
            variant="primary"
            badge
            :text="avatarText(user.name)+avatarText(user.family)"
            class="badge-minimal"
            badge-variant="success"
          />
          <!--  :src="require('@/assets/images/avatars/6-small.png')" -->
        </template>


        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="logOut"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Bookmarks from '@core/layouts/components/app-navbar/components/Bookmarks.vue'
import AdminDrop from '@core/layouts/components/app-navbar/components/AdminDrop.vue'
import { avatarText } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    Bookmarks,
    AdminDrop,
    // Navbar Components
    // eslint-disable-next-line vue/no-unused-components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: getUserData(),
      fullname: `${getUserData().name} ${getUserData().family}`,
      avatarText,
    }
  },
  methods: {
    logOut() {
      if (confirm('آیا مطمئن هستید؟')) {
        this.$store.dispatch('auth/logout')

        this.$router.push('/login')
      }
    },
  },
}
</script>
