export default [
  {
    title: 'مانیتورینگ',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'خودروهای من',
    route: 'device-list',
    icon: 'FileIcon',
  },
]
