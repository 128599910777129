<template>
  <b-card-header class="nav d-md-none" style="
    top: 0px;
    position: fixed;
    width: 100%;
    padding: 0px 5px;
    border-radius: 0 !important;
   background:rgb(0, 171, 253) !important;
   z-index: 1;
    height: 60px;">

    <b-col
        class="content-header-right  text-md-right " style="text-align: right;"
        mt="1"
        md="0"
        cols="1"
    >
      <b-button
          variant="link"
          @click="onCancel()"
          style="padding: 5px 0px 0px;"
      >
        <v-icon
            style="color: #fff;"
            size="25"
        >mdi-arrow-right-bold</v-icon>
      </b-button>

    </b-col>
  <div class="content-header-left m-0 pt-1 col-md-0 col-11" >
    <b-row class="breadcrumbs-top">

      <b-col cols="12">

        <div class="breadcrumb-wrapper">

          <b-breadcrumb class="pl-0">
            <b-breadcrumb-item
                v-for="item in breadcrumbList"
                :key="item.text"
                :active="item.active"
                :to="item.to"
                style="color: #fff;font-size: 1.2rem;text-align: center;width: 100%;"
            >
              {{ titlePage }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
    </b-row>
  </div>
  </b-card-header>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BCard, BCardHeader,BButton, BAvatar, BRow, BCol, BModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCardHeader,
    BCard, BButton, BRow, BCol, BAvatar, BModal, BForm, BFormInput, BFormGroup,
  },
  data() {
    return {
      titlePage: '',
      breadcrumbList: this.$route.meta.breadcrumb,
    }
  },
  computed: {

  },
  watch: {
    $route() {
      this.breadcrumbList = this.$route.meta.breadcrumb
    },
  },
  methods: {
    getTitle(title) {
      this.titlePage = title
    },
    onCancel() {
      this.$emit('on-change-page', 'home')
    },
  },
}
</script>
